/* -----------------------------------------------------------------------------
 *
 * Forms: Service Check
 *
 * ----------------------------------------------------------------------------- */

 @import '../../../styles/utilities/common.css';

.component {
  padding: 4px;
  border: 1px solid var( --color-shuttle-gray );
  border-radius: 40px;
  background-color: var( --color-white );

  &__dark {
    background-color: var( --color-ebony-clay );
  }

  &__isHero {
    margin-top: 45px;
    border: 0;
  }
}


/* -----------------------------------------------------------------------------
 * Form
 * ----------------------------------------------------------------------------- */

.component__form {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 0;
}

/* -----------------------------------------------------------------------------
  * Icon
  * ----------------------------------------------------------------------------- */

.component__icon {
  margin-right: 0;
  margin-left: 10px;
  color: var( --color-midnight-express );
  font-size: rem( 24px );

  @media (--large) {
    margin-right: 8px;
    margin-left: 16px;
  }

  .component__dark & {
    color: var( --color-white );
  }
}

/* -----------------------------------------------------------------------------
  * Form Field
  * ----------------------------------------------------------------------------- */

.component__formField {
  flex: 2;
  margin-bottom: 0;
  position: relative;

  @media (--hd) {
    display: inline-block;
    width: 275px;
  }
}

.component__formField input {
  position: relative;
  height: 42px;
  padding-left: 8px;
  border: 0;
  appearance: none;
  font-size: rem( 18px );

  @media (--medium) {
    height: 50px;
  }

  .component__dark & {
    background-color: var( --color-ebony-clay );
    color: var( --color-white );
  }
}

.component__formField input::placeholder {
  color: var( --color-midnight-express );
  font-size: rem( 14px );
  font-weight: 500;
  line-height: lh( 14px, 18px );

  @media (--small) {
    font-size: rem( 16px );
    line-height: lh( 16px, 20px );
  }

  .component__dark & {
    color: var( --color-white );
  }
}


/* -----------------------------------------------------------------------------
 * Form Button
 * ----------------------------------------------------------------------------- */

.component__form button[ type='submit' ] {
  display: block;
  width: auto;
  margin: 0;
  margin-left: 12px;
  padding: 8px;
  font-size: rem( 18px );
  line-height: lh( 18px, 22px );

  @media (--medium) {
    padding: 12px;
    display: inline;
  }
}

/* -----------------------------------------------------------------------------
 * Suggestions
 * ----------------------------------------------------------------------------- */

 .component__suggestions {
  display: none;
  position: absolute;
  z-index: 20;
  width: 100%;
  background-color: var( --color-white );
}

.component__suggestionsActive {
  display: block;
}

/* -----------------------------------------------------------------------------
 * Suggestions List
 * ----------------------------------------------------------------------------- */

.component__suggestionsList {
  max-height: 200px;
  margin-bottom: 0;
  padding-left: 0;
  overflow-x: scroll;
  list-style-type: none;

  li {
    margin-bottom: 0;
    border: 1px solid var( --color-ghost-gray );
    border-top: 0;
  }

  button {
    display: block;
    width: 100%;
    margin: 0;
    padding: 15px;
    border: 0;
    background-color: transparent;
    color: var( --color-midnight-express );
    font-size: rem( 16px );
    font-weight: 500;
    appearance: none;
    text-align: left;
    cursor: pointer;
  }

  button:hover,
  button:focus {
    transition: all 0.3s ease;
    outline: none;
    background-color: var( --color-zircon );
  }
}

/* -----------------------------------------------------------------------------
 * Suggestions Manual
 * ----------------------------------------------------------------------------- */

.component__manual {
  button {
    display: block;
    width: 100%;
    padding: 6px;
    border: 1px solid var( --color-ghost-gray );
    background-color: var( --color-white );
    color: var( --color-torch-red );
    font-size: rem( 14px );
    line-height: lh( 14px, 18px );
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
    appearance: none;
    box-sizing: border-box;
  }
}


/* -----------------------------------------------------------------------------
 * Compact GDT
 * ----------------------------------------------------------------------------- */

 .component__compact-gdt {
  @media (--medium) {
    width: 700px;
  }
 }
