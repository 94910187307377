/* -----------------------------------------------------------------------------
 *
 * Components: Hero Content Only
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/utilities/common.css';

.component {
  padding: 0;

  @media (--medium-max) {
    background-image: none !important;
  }

  @media (--medium) {
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }

  @media (--large) {
    background-position-x: calc( 50% + 350px );
  }
}

.component__mobileBackground {
  width: calc( 100% + 32px );
  height: 320px;
  transform: translateX( -15px );
  background-image: none;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  box-shadow: inset 0 0 15px 15px var( --color-midnight-express );

  @media (--medium) {
    display: none;
    box-shadow: none;
  }
}

.component__wrapper {
  padding-top: 24px;

  @media (--medium) {
    padding: 80px 10px;
  }

  @media (--large) {
    padding: 100px 0;
  }
}

.component__contentWrapper {
  color: var( --color-white );
}

.component__content {
  margin: 20px 0 30px;

  @media (--medium) {
    padding-right: 50px;
  }

  @media (--large) {
    margin: 30px 0 50px;
    padding: 0;
  }
}

.component__pricingWrapper {
  margin: 20px 0 30px;

  @media (--large) {
    margin: 50px 0;
  }
}

.component__selectAreasLegal {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: rem(14px);
}

.component__selectAreasLegal a {
  color: white; /* defaults links to white */
}

.component__NFLSTLegal a {
  color: white; /* defaults links to white */
}
