/* -----------------------------------------------------------------------------
 *
 * Components: Hero Address Check
 *
 * ----------------------------------------------------------------------------- */

@import './HeroContentOnly.module.css';

.component__wrapper {
  padding: 50px 0;
  overflow-x: clip;
  
  @media (--medium) {
    padding: 80px 10px;
    background: none;
  }

  @media (--large) {
    padding: 100px 0;
  }
}

.component__headline {
  margin: 0;
  padding-top: 0;
}

.component__subHeadline {
  margin: 16px 0 0;
  padding-top: 0;

  @media (--large) {
    margin: 32px 0 0;
  }
}

.component__selectAreasLegal {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: rem(14px);
}

.component__headline > span {
  display: block;
}

.component__formLead {
  display: flex;
  align-items: center;
  margin: 12px 0 20px 0;
}

.component__formWrapper {
  margin-top: 30px;
}

.component__icon {
  margin-right: 15px;
  color: var( --color-torch-red );
  font-size: rem( 34px );
}

/* --- Horizon headline --- */
.component__headlineWrapper {
  margin-bottom: 20px;
  padding: 0;

  @media (--medium) {
    margin-bottom: 30px;
  }

  @media (--large) {
    margin-bottom: 50px;
  }
}

.component__horizonHeadline {
  display: block;
  padding-top: 0;
  line-height: 1;
  margin-bottom: 10px;

  @media (--large) {
    font-size: rem( 56px );
  }

  @media (--xlarge) {
    font-size: unset;
  }
}

.component__horizonHeadline::before {
  content: '';
  display: inline-block;
  margin-right: 10px;
  height: 27px;
  width: 25px;
  background: var( --color-torch-red );

  @media (--medium) {
    width: 40px;
  }

  @media (--large) {
    height: 45px;
  }

  @media (--xlarge) {
    margin-right: 20px;
    width: 145px;
  }

  @media (min-width: 1600px) {
    width: 305px;
    margin-right: 40px;
  }
}
/* --- --- */

/* tooltip overrides */
.component__subHeadline button {
  font-size: 60%;
  vertical-align: middle;
  padding-right: 3px;
  padding-left: 3px;
}
