/* -----------------------------------------------------------------------------
 *
 * Components: Sticky Nav
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/utilities/common.css';

.component {
  display: none;
  z-index: 998;
  padding: 11px 0 10px 0;
  border-bottom: 1px solid var( --color-midnight-express );
  background-color: var( --color-midnight-express );

  @media (--large) {
    display: block;
  }
}

/* -----------------------------------------------------------------------------
 * Navigation
 * ----------------------------------------------------------------------------- */

.component__navigation {
  display: none;

  @media (--large) {
    display: block;
  }

  :global(.menu:not(.submenu)) {
    display: flex;
    flex-direction: row;
  }

  :global(.menu > li.menu-item-has-children) {
    position: relative;
  }

  :global(.menu > li.menu-item-has-children:hover > .submenu-wrap),
  :global(.menu > li.menu-item-has-children:focus > .submenu-wrap),
  :global(.menu > li.menu-item-has-children:focus-within > .submenu-wrap) {
    @media (--large) {
      display: block;
    }
  }

  :global(.menu > li.menu-item-has-children:hover > button .icon::before),
  :global(.menu > li.menu-item-has-children:focus > button .icon::before),
  :global(.menu > li.menu-item-has-children:focus-within > button .icon::before) {
    transform: rotate( 180deg );
    color: var( --color-torch-red );
  }
}

.component__navigation li {
  padding: 0 8px;
}

.component__navigation a {
  color: var( --color-zircon );
  font-size: rem( 12px );
  position: relative;

  &:hover,
  &:focus {
    color: var( --color-torch-red );
    text-decoration: none;
  }
}

.component__navigation li.stickNav__liActive a {
  color: var( --color-white );
  font-weight: 700;
}

.component__navigation li.stickNav__liActive a:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  background-color: var( --color-torch-red );
  display: block;

  @media (--large) {
    top: -11px; /* based on padding from outermost sticky nav element */
  }
}

.component__button {
  background-color: transparent;
  display: inline-block;
  border: 0;
  font-size: rem( 12px );
  padding: 0;
  text-transform: capitalize;

  &:hover:enabled,
  &:focus:enabled {
    color: var( --color-torch-red);
    background: inherit;
  }
}

.component__iconChevron {
  display: inline-block;
  font-size: rem( 12px );
  padding: 0 5px;

  &::before {
    display: inline-block;
    transition: transform 300ms ease;
  }
}

.component__submenuWrap {
  position: absolute;
  z-index: 10;
  left: 0;
  padding: 4px 0;
  width: max-content;
  background-color: var( --color-midnight-express );
  display: none;
}

.component__submenuWrap li > a {
  display: block;
  padding: 4px 0;
}

/* -----------------------------------------------------------------------------
 * Content
 * ----------------------------------------------------------------------------- */

.component__content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var( --color-white );
  font-size: rem( 15px );
  font-weight: 500;
  line-height: lh( 15px, 15px );

  @media (--large) {
    font-weight: 400;
    justify-content: space-between;
  }

  p {
    margin-bottom: 0;

    &:first-of-type {
      display: none;

      @media (--large) {
        display: block;
        font-size: rem( 12px );
      }

      @media (--hd) {
        font-size: rem( 12px );
      }
    }
  }
}

/* -----------------------------------------------------------------------------
 * Phone
 * ----------------------------------------------------------------------------- */

.component__phone {
  font-size: rem( 12px );
  font-weight: 500;
  line-height: lh( 14px, 16px );

  em {
    font-family: var( --font-primary );
    font-style: normal;
    font-weight: bold;
  }
}

.component__phoneIcon {
  margin: 0 6px;
  color: var( --color-torch-red );
  font-size: rem( 12px );
  width: 12px;
  height: 12px;
  display: inline-block;

  @media (--hd) {
    margin: 0 8px;
  }
}

/* -----------------------------------------------------------------------------
 * Call Center Closed
 * ----------------------------------------------------------------------------- */

[ data-is-open='false' ] .component__content {
  p {
    &:first-of-type {
      display: block;
      font-size: rem( 12px );

      @media (--small) {
        font-size: rem( 14px );
      }
    }
  }

  div {
    align-items: center;
    margin-bottom: 0;

    &:first-of-type:not(.component__submenuWrap) {
      display: flex;
    }
  }
}

.component__phoneClosed {
  font-size: rem( 15px );
  font-weight: 100;
  line-height: lh( 15px, 16px );

  a {
    margin-left: 8px;
    padding: 0 15px;
    font-size: rem( 12px );
    font-weight: 500;
    line-height: lh( 12px, 36px );

    @media (--large) {
      margin-left: 30px;
      padding: 0 30px;
    }
  }
}
