/* Color utils */
.u-color-blue {
  color: var( --color-electric-blue );
}

.u-color-red {
  color: var( --color-torch-red );
}

.u-color-midnight {
  color: var( --color-midnight-express );
}

.u-color-white {
  color: var( --color-white );
}

.u-theme-white {
  background-color: var( --color-white );
  color: var( --color-black );
}

.u-theme-light {
  background-color: var( --color-zircon );
}

.u-theme-dark {
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.u-theme-blue {
  background-color: var( --color-electric-blue );
}

.u-theme-red {
  background-color: var( --color-torch-red );
  color: var( --color-white );
}

.u-theme-black {
  background-color: var( --color-black );
  color: var( --color-white );
}

/* Visibility utils (taken from Foundation) */
@media print, screen and (max-width: 39.99875em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .show-for-medium {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .show-for-large {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}
